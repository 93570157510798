<template>
    <v-container grid-list-xl>
        <div v-if="blockedEdit">
            <v-alert outlined type="error" prominent border="left" class="ma-4"> Edição não permitida. </v-alert>
            <v-autocomplete v-model="item.store" :disabled="item.id != null" :items="stores" item-value="id" item-text="name" :label="$t('Loja')" @change="changeStore" />
            <v-chip class="ma-0" outlined color="success" v-if="item.date"> {{ $moment.utc(item.date).format("DD/MM/YYYY") }} </v-chip>
            <v-simple-table v-if="itemToEdit && itemToEdit.experts">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Especialista</th>
                            <th class="text-left">Inicio</th>
                            <th class="text-left">Fim</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in itemToEdit.experts" :key="item.id">
                            <td>{{ item.expert.name }}</td>
                            <td>{{ item.start }}</td>
                            <td>{{ item.stop }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div v-else>
            <h1>Planejamento de {{ planDay }}</h1>
            <v-divider class="mb-4" />
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
                <v-layout wrap>
                    <v-flex xs12 py-0>
                        <v-text-field inputmode="numeric" ref="datePlanning" :label="$t('Planejando dia')" v-model="datePlanning"
                            :rules="[rules.rangeDate('DD/MM/YYYY', $moment(), $moment().add({ days: 7 }))]" v-mask="'##/##/####'" :disabled="item.store != null" clearable />
                    </v-flex>
                    <v-flex xs12 py-0>
                        <v-autocomplete v-model="item.store" :disabled="item.id != null" :items="stores" item-value="id" item-text="name" :label="$t('Loja')" @change="changeStore"
                            clearable />
                    </v-flex>

                    <v-flex xs12 v-if="!item.store">
                        <v-alert outlined type="info" prominent border="left" class="ma-4"> Selecione a loja. </v-alert>
                    </v-flex>

                    <v-flex xs12 v-else-if="hasAlocationSaved">
                        <v-alert type="error" prominent border="left" class="ma-4">
                            Já existe um planejamento para essa loja nessa data. Favor edite o planejamento existente ao invés de criar mais um.
                        </v-alert>
                    </v-flex>

                    <v-flex xs12 v-else-if="!experts || experts.length == 0">
                        <v-alert type="warning" prominent border="left" class="ma-4"> Nenhum especisliata localizado. Verifique o planejamento. </v-alert>
                    </v-flex>

                    <v-flex xs12 v-else-if="!calendar || !calendar.expertNumber">
                        <v-alert type="warning" prominent border="left" class="ma-4">
                            Não localizado alocação de número de especialistas para esse turno. Verifique o planejamento.
                        </v-alert>
                    </v-flex>

                    <v-flex xs12 v-else>
                        <div class="row">
                            <div class="col-12 elevation-6 mb-4">
                                <h3>Especialistas Disponíveis</h3>
                                <v-progress-linear :value="(spent / calendar.expertNumber) * 100" height="25" :color="canAdd ? 'success' : 'error'">
                                    <strong>{{ spent }} de {{ calendar.expertNumber }} especialista(s)</strong>
                                </v-progress-linear>
                                <draggable class="dragArea list-group" :list="expertsAvailable" :sort="false" @end="changeAlocation"
                                    :group="{ name: 'people', pull: 'clone', put: false }">
                                    <div class="list-group-item" v-for="(expert, index) in expertsAvailable" :key="index">
                                        <v-list-item class="list-group-item" dense>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ expert.name }}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action v-if="expert.alocated">
                                                <v-chip color="blue" text-color="white" small>
                                                    <v-icon small left> info </v-icon>
                                                    Alocado {{ expert.alocated }} turno(s)
                                                </v-chip>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-divider />
                                    </div>
                                </draggable>
                            </div>
                            <v-col v-for="(shift, shiftIndex) in item.scheduling" :key="shiftIndex" class="elevation-6 mt-4" :cols="calcColumn">
                                <h3>Turno de {{ shift.start }} até {{ shift.stop }}</h3>
                                <v-progress-linear value="0" height="25" :color="canAdd ? 'success' : 'error'">
                                    <strong>{{ shift.experts.length }} especialista(s)</strong>
                                </v-progress-linear>

                                <draggable class="dragArea list-group ma-4 elevation-24" :sort="false" :list="shift.experts" :group="{ name: 'people', put: canAdd }">

                                    <template v-for="(expert, index) in shift.experts">
                                        <v-list-item class="list-group-item" :key="index" dense>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="expert.name"></v-list-item-title>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-icon color="red lighten-1" small @click="removeAlocation(expert, shiftIndex)"> delete </v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </draggable>
                            </v-col>
                        </div>
                    </v-flex>

                    <v-flex xs12 mt-12>
                        <v-btn color="success" @click="validate">Salvar</v-btn>
                    </v-flex>
                </v-layout>
            </v-form>
        </div>
    </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

const itemClean = { id: null, store: null, date: null, scheduling: [], experts: [] };

export default {
    directives: { mask },

    components: {
        draggable,
    },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            hasAlocationSaved: false,
            valid: false,
            blockedEdit: false,
            item: JSON.parse(JSON.stringify(itemClean)),
            experts: null,
            calendar: null,
            stores: null,
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    this.blockedEdit = false;
                    if (newVal != null) {
                        if (this.$moment.utc(newVal.date).isBefore(this.$moment.utc().subtract(1, "d"))) {
                            this.blockedEdit = true;
                        }
                        this.item.date = this.$moment.utc(newVal.date);

                        this.item.id = newVal.id;
                        this.item.store = newVal.store;
                        // this.datePlanning = this.$moment.utc(newVal.date).format("DD/MM/YYYY");

                        if (!this.blockedEdit) {
                            this.getCalendar();
                            this.getExperts();
                        }
                    } else {
                        this.item = JSON.parse(JSON.stringify(itemClean));
                        this.item.date = this.$moment.utc().startOf("day").add(1, "days");
                    }
                });
            },
        },
    },

    computed: {
        spent() {
            return this.item.scheduling.reduce((sum, i) => (i.experts.length ? sum + i.experts.length : sum), 0);
        },
        canAdd() {
            return this.spent < this.calendar.expertNumber;
        },
        datePlanning: {
            get: function () {
                return this.$moment.utc(this.item.date).format("DD/MM/YYYY");
            },
            set: function (newDate) {
                if (this.$refs.datePlanning) {
                    if (this.$refs.datePlanning.validate() && newDate != null && newDate.length > 0) {
                        this.item.date = this.$moment.utc(newDate, "DD/MM/YYYY");
                    } else if (this.item && newDate == null && this.item.date != null) {
                        // this.item.date = null;
                    }
                }
            },
        },
        planDay() {
            return this.calendar ? this.$t(this.calendar.day) : "???";
        },
        expertsAvailable() {
            return this.experts; //.filter((e) => !e.expert.alocated || e.expert.alocated <= 2);
        },
        calcColumn() {
            // let size = this.scheduleEnabled.length;
            // return 12 / size;
            return 12;
        },
    },

    methods: {
        // canAdd(index) {
        // return this.item.scheduling[index].experts.length < this.calendar.expertNumber;
        // },
        validate() {
            if (this.$refs.form && this.$refs.form.validate()) {
                this.item.experts = [];
                this.item.scheduling.forEach((s) => {
                    s.experts.forEach((e) => {
                        this.item.experts.push({ start: s.start, stop: s.stop, interval: s.interval, expert: e.id });
                    });
                });
                if (this.item.experts.length > 0) {
                    this.$emit("save", this.item);
                } else {
                    this.$eventHub.$emit("msgError", this.$t("Nenhum planejamento detectado, faça o planejamento antes de salvar."));
                }
            }
        },
        removeAlocation(shift, index) {
            this.experts.forEach((e) => {
                if (e.id == shift.id && e.alocated > 0) {
                    e.alocated = e.alocated - 1;
                }
            });
            this.item.scheduling[index].experts.splice(this.item.scheduling[index].experts.indexOf(shift), 1);
        },
        changeAlocation() {
            this.item.scheduling.forEach((s, index) => {
                this.item.scheduling[index].experts = s.experts.reduce((list, expert) => {
                    const found = list.find((item) => item.id === expert.id);
                    if (!found) {
                        return list.concat([expert]);
                    } else {
                        return list;
                    }
                }, []);
            });
        },
        changeStore() {
            this.item.scheduling = [];
            this.experts = null;
            this.calendar = null;
            this.hasAlocationSaved = false;
            if (this.item.store) {
                this.getExperts();
                this.getCalendar();
            }
        },
        getStores() {
            this.stores = null;
            this.$http.get('stores?enable=true').then((result) => {
                if (result) {
                    this.stores = result;
                }
            });
        },
        getExperts() {
            if (this.item.store) {
                this.experts = null;
                this.$http.get(`planning/experts/${this.item.store}`).then((result) => {
                    if (result) {
                        this.experts = result;
                    }
                });
            }
        },
        getCalendar() {
            if (this.item && this.item.store && this.item.date) {
                this.calendar = null;
                this.hasAlocationSaved = false;
                this.$http.get(`planning/calendar/${this.item.store}?date=${this.item.date.toISOString()}`).then((result) => {
                    if (result) {
                        if (this.item.id == null && result.hasAlocationSaved) {
                            // Only create a new item if not exist
                            this.hasAlocationSaved = true;
                        } else {
                            this.calendar = result;
                            this.item.scheduling = [];
                            this.calendar.schedule
                                .filter((f) => f.enable == true)
                                .forEach((s) => {
                                    this.item.scheduling.push({ start: s.start, stop: s.stop, interval: s.interval, experts: [] });
                                });

                            if (this.itemToEdit && this.itemToEdit.experts && this.itemToEdit.experts.length > 0) {
                                this.itemToEdit.experts.forEach((expert) => {
                                    this.item.scheduling.forEach((schedule) => {
                                        if (schedule.start == expert.start && schedule.stop == expert.stop) {
                                            schedule.experts.push(expert.expert);
                                        }
                                    });
                                });
                            }
                        }
                    }
                });
            }
        },
    },

    mounted() {
        this.getStores();
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>